import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { SliderWrap } from './styles';
import { SliderProps } from './types';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';



const Slider = ({list}: SliderProps) => {

  const [slideLength, setSliderLength] = useState(4)

  const handleResize = () => {
    if (window.innerWidth > 1024) {
      setSliderLength(4)
    } else if (window.innerWidth > 768) {
      setSliderLength(3)
    } else {
      setSliderLength(2)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // 현재 화면의 너비를 감지하기 위한 상태와 이펙트
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return list.length > 0 ? (
    windowWidth > 500 ? (
      <SliderWrap>
        {
          list.length > slideLength && (
            <div className='arrow-wrap'>
              <button type='button' className='slider-prev ir'>이전</button>
              <button type='button' className='slider-next ir'>다음</button>
            </div>
          )
        }
        <Swiper
          loop={list.length > slideLength ? true : false}
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={2}
          navigation={{
            prevEl: ".slider-prev",
            nextEl: ".slider-next",
          }}
          breakpoints={{     
            768:{
              slidesPerView: 3
            },    
            1024:{
              slidesPerView: 4
            }
          }}
        >
          {
            list.map((item, index) => {
              return (
                <SwiperSlide 
                  key={index}
                >
                  <Link 
                    to={`/shop/detail/${item.product_id}`}
                    className='item'
                  >
                    <div 
                      className='figure'
                      style={{background: `url('${process.env.REACT_APP_BASE_URL}${item.img}') no-repeat center / cover`}}
                    ></div>
                    <div className='info'>
                      <div className='title'>{item.name}</div>
                      <div className='text'>권종 선택구매</div>
                    </div>
                  </Link>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </SliderWrap>
    ) : (
      <SliderWrap>
        <div className='list-wrap'>
          {
            list.map((item, index) => {
              return (
                <div 
                  key={index}
                  className='box'
                >
                  <Link 
                    to={`/shop/detail/${item.product_id}`}
                    className='item'
                  >
                    <div 
                      className='figure'
                      style={{background: `url('${process.env.REACT_APP_BASE_URL}${item.img}') no-repeat center / cover`}}
                    ></div>
                    <div className='info'>
                      <div className='title'>{item.name}</div>
                      <div className='text'>권종 선택구매</div>
                    </div>
                  </Link>
                </div>
              )
            })
          }
        </div>
      </SliderWrap>
    )
  ) : (
    <SliderWrap>
      <div className="empty">
        등록된 상품이 없습니다.
      </div>
    </SliderWrap>
  )
}

export default Slider