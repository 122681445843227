import styled from "styled-components";

export const SliderWrap = styled.div`
  position: relative;
  .arrow-wrap {
    display: flex;
    align-items: center;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: -8rem;
    button {
      width: 5rem;
      height: 5rem;
      &.slider-prev {
        background: url('/image/prev-icon.png') no-repeat center #fff;
      }
      &.slider-next {
        background: url('/image/next-icon.png') no-repeat center #fff;
      }
    }
  }
  .item {
    display: block;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
    overflow: hidden;
    transition: all ease 0.3s;
    &:hover {
      border-color: #8b00ff;
    }
    .figure {
      width: 100%;
      padding-top: 100%;
    }
    .info {
      padding: 2rem 1.5rem;
      .title {
        font-size: 2rem;
        font-weight: 500;
      }
      .text{
        font-size: 1.6rem;
        color: #8b00ff;
        margin-top: 1rem;
      }
    }
  }
  .empty {
    width: 100%;
    height: auto;
    padding: 14rem 0;
    text-align: center;
    font-size: 1.6rem;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    .arrow-wrap {
      position: unset;
      width: 10rem;
      margin-bottom: 2rem;
      margin-left: auto;
    }
  } 
  @media screen and (max-width: 500px) {
    .item {
      .info {
        padding: 1.5rem;
        .title {
          font-size: 1.6rem;
        }
        .text {
          font-size: 1.4rem;
        }
      }
    }
    .arrow-wrap {
      width: 6rem;
      button {
        width: 3rem;
        height: 3rem;
        &.slider-prev {
          background-size: 7px;
        }
        &.slider-next {
          background-size: 7px;
        }
      }
    }
    .list-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5rem;
      overflow-y: auto;
      height: 68rem;
      .box {
        width: 50%;
        padding: 0 0.5rem;
        &:nth-child(n + 3) {
          margin-top: 1rem;
        }
        .item {
          height: 100%;
          .info {
            .title {
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap;
            }
          }
        }
      }
    }
  } 
`
