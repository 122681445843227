import styled from "styled-components";

export const BoardWrap = styled.div`
  .boad-list {
    width: 100%;
    height: auto;
    border-top: 0.1rem solid #dfdfdf;
    li {
      width: 100%;
      height: 4.8rem;
      border-bottom: 0.1rem solid #dfdfdf;
      &.hd {
        display: flex;
        align-items: center;
        > div {
          text-align: center;
          font-size: 1.4rem;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      > a {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        &:hover {
          background-color: #eee;
        }
        > div {
          text-align: center;
          font-size: 1.4rem;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .no {
        width: 10rem;
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 40rem);
        img {
          width: 1.6rem;
          margin-left: 0.5rem;
        }
      }
      .name {
        width: 12rem;
      }
      .date {
        width: 18rem;
      }
      &.empty {
        text-align: center;
        height: 20rem;
        span {
          text-align: center;
          font-size: 1.4rem;
          line-height: 20rem;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .boad-list {
      li {
        height: auto;
        &.hd {
          display: none;
        }
        a {
          padding: 1.5rem;
          > div {
            text-align: left;
          }
        }
        .no {
          width: 100%;
          margin-bottom: 1rem;
        }
        .title {
          width: 100%;
          justify-content: flex-start;
          margin-bottom: 1rem;
        }
        .name {
          width: auto;
          margin-right: 1rem;
          padding-right: 1rem;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: 1.2rem;
            background-color: rgba(0, 0, 0, 0.87);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .date {
          width: auto;
        }
      }
    }
  }
`

export const BoardDetailWrap = styled.div`
  width: 100%;
  height: auto;
  border-top: 0.2rem solid #dfdfdf;
  .hd {
    padding: 1.6rem;
    border-bottom: 0.1rem solid #dfdfdf;
    .title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.3;
      }
      .date {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.3;
      }
    }
    .info-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      span {
        font-size: 1.6rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .file-wrap {
    padding: 1.6rem;
    border-bottom: 0.1rem solid #dfdfdf;
    a, button {
      width: 18rem;
      height: 4rem;
      border-radius: 0.4rem;
      border: 0.1rem solid #8b00ff;
      transition: all ease 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(255, 181, 0, 0.1);
      }
      span {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        color: #8b00ff;
        margin-left: 1rem;
      }
    }
  }
  .contents {
    padding: 1.6rem;
    min-height: 40rem;
    border-bottom: 0.1rem solid #dfdfdf;
  }
  .ft {
    padding: 1.6rem;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 10rem;
      height: 3.6rem;
      border: 0.1rem solid rgba(0, 0, 0, 0.87);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.4rem;
      img {
        width: 2.4rem;
        margin-right: 0.5rem;
      }
      span {
        display: block;
        font-size: 1.4rem;
        line-height: 1;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  @media screen and (max-width: 768px) {
    .hd {
      .title-wrap {
        display: block;
        .date {
          margin-top: 0.5rem;
        }
      }
      .info-wrap {
        display: block;
        margin-top: 1rem;
        span {
          display: block;
          &:last-child {
            margin-top: 1rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .hd {
      padding: 1.5rem;
      .title-wrap {
        h4 {
          font-size: 1.4rem;
        }
        .date {
          font-size: 1.4rem;
        }
      }
      .info-wrap {
        span {
          font-size: 1.4rem;
        }
      }
    }
    .contents {
      font-size: 1.4rem;
      padding: 1.5rem;
    }
    .ft {
      padding: 1.5rem;
      button {
        width: 9rem;
        height: 3rem;
        border-radius: 0.2rem;
      }
    }
  }
`
