import styled from "styled-components";

export const AboutWrap = styled.div`
  padding: 9rem 0 15rem;
  .img-wrap {
    text-align: center;
  }
  .text-wrap {
    padding: 8rem 0;
    border-bottom: 0.1rem solid #dfdfdf;
    p {
      font-size: 3.2rem;
      text-align: center;
      word-break: keep-all;
      span {
        color: #fca932;
        font-weight: 600;
      }
    }
  }
  .info-wrap {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-top: 8rem;
    > div {
      width: 50%;
      height: auto;
    }
    .info {
      dl {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 2.5rem;
        }
        dt {
          width: 10rem;
          height: 3rem;
          border-radius: 1.5rem;
          font-size: 1.6rem;
          line-height: 3rem;
          font-weight: 600;
          color: #fff;
          text-align: center;
          background-color: #8b00ff;
          margin-right: 2rem;
        }
        &:nth-child(even) {
          dt {
            background-color: #fca932;
          }
        }
        dd {
          width: calc(100% - 12rem);
          font-size: 1.6rem;
          color: #707070;
          word-break: keep-all;
        }
      }
    }
    .map {
      iframe {
        width: 100%;
        height: 30rem;
        border: 0;
        border-radius: 2rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    .text-wrap {
      padding: 6rem 0;
      p {
        font-size: 2.6rem;
        br {
          display: none;
        }
      }
    }
    .info-wrap {
      padding-top: 4rem;
      .info {
        dl {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
          dt {
            width: 8rem;
            font-size: 1.4rem;
            margin-right: 1rem;
          }
          dd {
            width: calc(100% - 9rem);
            font-size: 1.4rem;
          }
        }
      }
      .map {
        margin-top: 3rem;
        iframe {
          height: 25rem;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
    .text-wrap {
      padding: 4rem 0;
      p {
        font-size: 1.8rem;
        br {
          display: none;
          word-break: keep-all;
        }
      }
    }
    .info-wrap {
      padding-top: 6rem;
      display: block;
      > div {
        width: 100%;
      }
      .map {
        margin-top: 4rem;
      }
    }
  }
`

export const TitleWrap = styled.div`
  margin-bottom: 4rem;
  h2 {
    font-size: 2.6rem;
    font-weight: 600;
    color: #8b00ff;
    text-align: center;
    position: relative;
    padding-bottom: 2rem;
    &::after {
      content: '';
      display: block;
      width: 0.8rem;
      height: 0.1rem;
      background-color: #8b00ff;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 3rem;
    h2 {
      font-size: 2.2rem;
      padding-bottom: 1.5rem;
    }
  }
`
