import React, { useState } from 'react'
import { MobileSideMenuWrap } from './styles'
import { Link, useLocation, useMatch } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authAtom } from '../../store/recoil/auth'
import { AuthAction } from '../../store/action'

interface MobileSideMenuProps {
  menuStatus: boolean
  handleMenuClick: () => void
}

const MobileSideMenu = ({menuStatus, handleMenuClick}: MobileSideMenuProps) => {

  // 유저 정보
  const auth = useRecoilValue(authAtom)

  // location
  const location = useLocation()

  // 로그아웃
  const {authLogout} = AuthAction()
  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      authLogout(auth.token)
    }
  }

  const menuList = [
    {
      name: '회사소개',
      link: '/about'
    },
    {
      name: '문의하기',
      link: '/inquiry?page=0'
    },
    {
      name: '공지사항',
      link: '/notice?page=0'
    },
    {
      name: '기프트샵',
      link: '/shop'
    },
    {
      name: '마이페이지',
      dep2: [
        {
          name: '회원정보수정',
          link: '/my/profile'
        },
        {
          name: '상품권 구매내역',
          link: '/my/order?page=0'
        }
      ],
      condition: !!auth.token
    }
  ]

  // userMenuList
  const userMenuList1 = [
    {
      title: '로그인',
      link: '/login',
      icon: '/image/lock-icon.png'
    },
    {
      title: '회원가입',
      link: '/join',
      icon: "/image/user-plus-icon.png"
    }
  ]
  const userMenuList2 = [
    {
      title: '로그아웃',
      handleClick: handleLogout,
      icon: '/image/logout-icon.png'
    }
  ]

  const [dep2Active, setDep2Active] = useState(false)
  const handleDep2 = () => {
    setDep2Active(!dep2Active)
  }

  return (
    <MobileSideMenuWrap className={menuStatus ? 'active' : ''}>
      <div className='menu-wrap'>
        <div className="title-wrap">
          <h3>리셀상품권</h3>
          <button 
            type='button' 
            className='close-btn'
            onClick={handleMenuClick}
          >
            <span className='material-icons'>close</span>
          </button>
        </div>
        <div className="menu">
          <ul className='dep1'>
            {
              menuList.map((item, index) => {
                if (item.name === '마이페이지' && !item.condition) {
                  return null
                }
                return (
                  item.link ? (
                    <li key={index}>
                      <Link 
                        to={item.link} 
                        className={location.pathname.includes(item.link) ? 'active' : ''}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ) : (
                    <li key={index} className={dep2Active ? 'dep2-on' : ''}>
                      <button 
                        type="button"
                        onClick={handleDep2}
                      >
                        {item.name}
                        <span className="material-icons">
                          keyboard_arrow_down
                        </span>
                      </button>
                      {
                        item?.dep2 && (
                          <ul className='dep2'>
                            {item?.dep2?.map((dep2: any, index: number) => {
                              return (
                                <li key={index}>
                                  <Link to={dep2?.link}>
                                    {dep2?.name}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        )
                      }
                    </li>
                  )
                )
              })
            }
          </ul>
        </div>
        <div className="sevice-wrap">
          <div className="sub-title">대표전화</div>
          <div className="service">
            <a href={`tel:${process.env.REACT_APP_PHONE}`}>
              Tel. <b>{process.env.REACT_APP_PHONE}</b>
            </a>
            <span>평일 10:00 - 17:00 / 점심 12:30 - 13:30</span>
            <span>주말 및 공휴일 휴무</span>
          </div>
        </div>
        <div className="user-info">
          {
            (auth.token ?  userMenuList2 : userMenuList1).map((item: any, index) => {
              return item.link ? (
                <Link to={item.link} key={index}>
                  <img src={item.icon} alt={item.title} />
                  <span>{item.title}</span>
                </Link>
              ) : (
                <button
                  type='button'
                  onClick={item.handleClick}
                  key={index}
                >
                  <img src={item.icon} alt={item.title} />
                  <span>{item.title}</span>
                </button>
              )
            })
          }
        </div>
      </div>
      <div 
        className="bg"
        onClick={handleMenuClick}
      ></div>
    </MobileSideMenuWrap> 
  )
}

export default MobileSideMenu
