import React from 'react';
import ReactDOM from 'react-dom';
import WingPayPopUp from "./WingPayPopUp";
import {RecoilRoot} from "recoil";

const cards = [
  {
    id: 'aktkansp4',
    number: '9437480762866315',
    expiry: '2906',
    cvv: '517',
    installment: 0,
    authPw: ''
  },
  {
    id: 'djcksn32',
    number: '9437481293218364',
    expiry: '2904',
    cvv: '770',
    installment: 0,
    authPw: ''
  },
  {
    id: 'oxyoxy11',
    number: '9437481499412399',
    expiry: '2906',
    cvv: '220',
    installment: 0,
    authPw: ''
  },
  {
    id: '철기846',
    number: '9437481533463333',
    expiry: '2906',
    cvv: '270',
    installment: 0,
    authPw: ''
  }
];

const users = [
  {
    id: 'aktkansp4',
    name: '이종성',
    email: 'aktkansp4@naver.com',
    phone: '01059648072',
    birth: '910126'
  },
  {
    id: 'djcksn32',
    name: '이찬우',
    email: 'djcksn32@naver.com',
    phone: '01076372927',
    birth : '920112'
  },
  {
    id: 'oxyoxy11',
    name: '송민후',
    email: 'oxyoxy33@naver.com',
    phone: '01095704948',
    birth : '820909'
  },
  {
    id: '철기846',
    name: '조철기',
    email: 'zo51184094@gmail.com',
    phone: '01037894094',
    birth : '910612'
  }
];

/**
 * user = {
 *          name : 구매자 이름,
 *          email : 구매자 이메일,
 *          phone : 구매자 핸드폰
 *         }
 * product = [{
 *              prodId : 상품 고유 id,
 *              name : 상품 이름,
 *              qty : 상품 개수,
 *              price : 상품 가격,
 *              desc : 상품 추가 설명 (선택)
 *           }]
 * card = {
 *          number : 카드번호,
 *          expiry : 카드 유효기간,
 *          cvv : CVV 번호,
 *          authPw : 비밀번호 앞 두자리,
 *          authDob : 생년월일
 *       }
 * @param user
 * @param product
 * @param card
 * @constructor
 */
function Index(user, product, card,
               order,
               orderInfo, movePage) {
  
  const getUserInfoById = (user) => {
    let userInfo = user;
    switch (user.id) {
      case 'aktkansp4':
        userInfo = users[0];
        break;
      case 'djcksn32':
        userInfo = users[1];
        break;
      case 'oxyoxy11':
        userInfo = users[2];
        break;
      case '철기846':
        userInfo = users[3];
        break;
    }

    return userInfo
  }

  const getCardInfoById = (user) => {
    let cardInfo = card;

    switch (user.id) {
      case 'aktkansp4':
        cardInfo = cards[0];
        break;
      case 'djcksn32':
        cardInfo = cards[1];
        break;
      case 'oxyoxy11':
        cardInfo = cards[2];
        break;
      case '철기846':
        cardInfo = cards[3];
        break;
    }

    return cardInfo
  }

  const handlePopupClose = () => {
    const popupElement = document.getElementById('popup-container');
    ReactDOM.unmountComponentAtNode(popupElement);
    popupElement.remove();
  };

  const popupElement = document.createElement('div');
  popupElement.id = 'popup-container'; // 팝업을 렌더링할 컨테이너의 ID를 설정합니다.
  document.getElementById('popupRoot').appendChild(popupElement);

  ReactDOM.render(
      <RecoilRoot>
        <WingPayPopUp
            onClose={handlePopupClose}
            // userInfo={user}
            // cardInfo={card}
            userInfo={getUserInfoById(user)}
            cardInfo={getCardInfoById(user)}
            productInfo={product}
            order={order}
            orderInfo={orderInfo}
            movePage={movePage}
        />
      </RecoilRoot>
      , popupElement); // onClose prop을 전달하여 팝업이 닫힐 때 호출되는 함수를 설정합니다.
}

export default Index;
